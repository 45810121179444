<template>
  <div>
    <div>
      <b-badge
        :variant="
          status.value === selectedStatus ? 'primary' : 'light-secondary'
        "
        v-for="status in statuses"
        :key="status.id"
        pill
        :class="'mr-1 mb-1 cursor-pointer badge-padding'"
        @click="statusSelection(status.value, false)"
      >
        {{ $t(status.name) }}
      </b-badge>
    </div>
    <b-card class="mt-306">
      <div class="adduser-btn-row">
        <h4 class="card-title">
          {{ $t("userInvitations.Invitations") }}
        </h4>
        <div class="filter-btn-outer-new">
          <b-button
            variant="primary"
            size="sm"
            class="desktop-hidden"
            @click="showFiltermenu = !showFiltermenu"
          >
            <feather-icon icon="FilterIcon" size="15" />
          </b-button>
        </div>

        <div
          class="d-flex flex-wrap filter-card search-display-1"
          v-if="showFiltermenu"
        >
          <div class="notification-per-page">
            <div class="addXIcon">
              <feather-icon
                class="sizeIcon"
                @click="showFiltermenu = !showFiltermenu"
                icon="XIcon"
                size="15"
              />
            </div>
            <label class="search-label mt-1">{{ $t("unit.Entries") }}</label>
            <v-select
              :clearable="false"
              id="vue-select"
              class="per-page-selector d-inline-block fix-83 mr-1"
              v-model="perPage"
              :options="option"
            />
          </div>
          <search
            style="float: left"
            class="mobile-margin-top1 mb-0"
            v-model="searchFiled"
            :searchColumn="searchColumn"
            :operator="operator"
          ></search>
        </div>
      </div>
      <UserInvitationList
        :searchFiled="searchFiled"
        :perPage="perPage"
        :selectedStatus="selectedStatus"
      ></UserInvitationList>
    </b-card>

    <b-modal
      id="modal-sm-no-account"
      :title="$t('NoAccountsFound')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="no-header-modal"
    >
      <b>{{ $t("NoAccountsFoundMSG") }}</b>

      <div class="d-flex justify-content-center mb-64 mtt-40">
        <b-button variant="primary" class="mt-2 mr-1" @click="close">
          {{ $t("user.Ok") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip,
  BFormInput,
  BRow,
  BCol,
  BBadge,
  BModal
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Search from "../Common/search.vue";
import UserInvitationList from "@/views/UserInvitations/UserInvitationsList.vue";
import constants from "@/utils/constants";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BTooltip,
    VBTooltip,
    UserInvitationList,
    vSelect,
    BFormInput,
    BRow,
    BCol,
    BBadge,
    Search,
    BModal
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      showFiltermenu: false,
      searchColumn: ["Account", "Role"],
      searchFiled: {},
      operator: "ilike",
      selected: "10",
      perPage: 10,
      option: ["10", "20", "30"],
      statuses: [
        { id: 1, name: "userInvitations.Pending", value: "pending" },
        { id: 2, name: "userInvitations.All", value: "all" },
        { id: 3, name: "userInvitations.Accepted", value: "accepted" },
        { id: 4, name: "userInvitations.Rejected", value: "rejected" },
        { id: 5, name: "userInvitations.Expired", value: "expired" }
      ],
      selectedStatus: "all"
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.tab) {
        this.selected_tab = this.$route.query.tab;
        this.statusSelection(this.$route.query.tab, true);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
    if (this.$route.query.tab) {
      this.selected_tab = this.$route.query.tab;
      this.statusSelection(this.$route.query.tab, true);
    }
    const { perPage } = this.$route.query;

    if (perPage) {
      this.perPage = parseInt(perPage);
    }
    if (!localStorage.getItem("USER_ACCOUNT_NAME")) {
      this.$bvModal.show("modal-sm-no-account");
    }
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    close() {
      this.$bvModal.hide("modal-sm-no-account");
    },
    statusSelection(status, isRedirect) {
      this.selectedStatus = status ? status : "all";
      if (!isRedirect) {
        this.$router.push({ name: "user-invitations", query: { tab: status } });
      }

      this.$route.meta.breadcrumb = JSON.parse(
        JSON.stringify(constants.USER_INVITATION_ROUTE_META_BREADCRUMB)
      );
      let breadcrumb;
      if (status == "pending") {
        breadcrumb = {
          text: "Pending",
          index: "pending",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else if (status == "accepted") {
        breadcrumb = {
          text: "Breadcrumb_Accepted",
          index: "accepted",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else if (status == "rejected") {
        breadcrumb = {
          text: "Breadcrumb_Rejected",
          index: "rejected",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else if (status == "expired") {
        breadcrumb = {
          text: "Breadcrumb_Expired",
          index: "expired",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      } else {
        breadcrumb = {
          text: "Breadcrumb_All",
          index: "all",
          active: true
        };
        this.$route.meta.breadcrumb.push(breadcrumb);
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.badge-padding {
  padding: 10px 15px;
}
@media only screen and (max-width: 991px) {
  .notification-per-page {
    margin-left: 0;
    margin-right: 0px;
    width: 100%;
  }
  .mobile-margin-top1 {
    margin-top: 15px;
  }
  .search-display-1 {
    .search-filter {
      display: block !important;
      min-width: 100%;
    }
  }
  .filter-card {
    width: 100%;
    max-width: 300px;
    position: absolute;
    top: 52px;
    z-index: 9;
  }
}
</style>
<style lang="scss" scoped>
.mt-306 {
  height: calc(100vh - 170px);
  overflow-y: hidden;
}
</style>
