var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[(_vm.show)?_c('div',{staticClass:"table-responsive1"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":1,"columns":7,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative no-headers invitations-li-h",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.items,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(user)",fn:function(row){return [_c('b-media',{staticClass:"align-item-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":"RA","text":row.item.avtar_txt,"variant":row.item.variant}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.item.account_name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.item.role_name))])])]}},{key:"cell(time)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatTime(row.item.mtime))+" ")]}},{key:"cell(action)",fn:function(row){return [(
            row.item.status === 'Accepted' ||
            row.item.status === 'Rejected' ||
            row.item.status === 'Expired'
          )?_c('b-badge',{staticClass:"mr-2",attrs:{"pill":"","variant":row.item.status === 'Accepted'
              ? 'light-success'
              : row.item.status === 'Rejected'
              ? 'light-danger'
              : row.item.status === 'Expired'
              ? 'light-danger'
              : ''}},[_vm._v(" "+_vm._s(_vm.$t(("user.Status." + (row.item.status)))))]):_vm._e(),(row.item.status === 'Pending')?_c('div',{staticClass:"d-flex justify-content-between active-status-btn"},[_c('b-button',{staticClass:"mr-1 ml-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.joinWithUserInvite('ACCEPT', row.item.token)}}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"size":"16"}}),_c('span',{staticClass:"d-sm-inline"},[_vm._v(_vm._s(_vm.$t("userInvitations.Accept")))])],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.joinWithUserInvite('DECLINE', row.item.token)}}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"size":"16"}}),_c('span',{staticClass:"d-sm-inline"},[_vm._v(_vm._s(_vm.$t("userInvitations.Reject")))])],1)],1):_vm._e()]}}],null,false,2398660228)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalUsers > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }