<template>
  <div
    style="position: relative"
    class="search-filter d-flex align-items-center"
  >
    <label v-if="!hideLabel" class="pr-1 mb-0">{{
      $t("userInvitations.SearchTitle")
    }}</label>
    <b-form-group class="next-child">
      <b-input-group class="height-100-child">
        <b-form-input
          :placeholder="
            searchPlaceholder ? searchPlaceholder : $t('userInvitations.Search')
          "
          v-b-toggle.collapse-1
          v-model="search_txt"
          ref="search_input"
          @keyup.enter="selectIcon()"
          @input="clickTxt()"
          :class="
            visible
              ? 'search-icon-input collapsed-icons d-inline-block search-bg'
              : 'search-icon-input d-inline-block search-bg'
          "
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
        ></b-form-input>
        <b-input-group-append
          class="input-prefix-img search-clear curserPointer"
        >
          <feather-icon icon="XIcon" @click="clearTxt" size="20" />
        </b-input-group-append>
      </b-input-group>

      <div v-if="visible" ref="divRef" class="search-icon-modal mb-1">
        <b-card class="search-card">
          <div
            v-for="(field, index) in searchColumn"
            :key="index"
            class="searchable-item"
          >
            <span @click="selectIcon(field)" class="curserPointer">
              {{ $t("searchIn") }} {{ field }} <b>" {{ search_txt }} "</b>
            </span>
          </div>
        </b-card>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BFormInput,
  BCol,
  VBToggle,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend
} from "bootstrap-vue";

// Note: Vue automatically prefixes the directive name with 'v-'

export default {
  name: "search",
  components: {
    BCard,
    BCollapse,
    BFormInput,
    BCol,
    VBToggle,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend
  },
  props: [
    "searchColumn",
    "value",
    "operator",
    "searchPlaceholder",
    "hideLabel"
  ],

  data() {
    return {
      test: "",
      visible: false,
      search_txt: "",

      filter: {}
    };
  },
  directives: {
    "b-toggle": VBToggle
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    if (this.$route.name === "unit-map") {
      if (this.$route.query && this.$route.query.filter) {
        this.$emit("input", {
          field: "all",
          operator: "ilike",
          value: this.$route.query.filter
        });
      }
      this.$router
        .replace({
          query: this.$route.query.filter
            ? { ...this.$route.query, filter: this.$route.query.filter }
            : { ...this.$route.query }
        })
        .catch(() => {});
    }
    this.$emit("input", {
      field: "all",
      operator: "ilike",
      value: this.search_txt
    });
    this.handleFocusSearch();
  },
  watch: {
    search_txt(val) {
      this.handleFocusSearch();
      // const page = this.$route.query.page
      //   ? { page: this.$route.query.page }
      //   : {};
      // const alert_id = this.$route.query.alert_id
      //   ? { alert_id: this.$route.query.alert_id }
      //   : {};
      // const query = Object.assign({}, this.$route.query);
      // if (query) {
      //   delete query.filter;
      // }
      // this.$router.replace({ query }).catch(() => {});
    }
  },
  computed: {},
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  created() {
    this.search_txt = this.$route.query.filter || "";
    this.handleFocusSearch();
  },
  methods: {
    handleFocusSearch() {
      if (this.$refs && this.$refs.search_input && window.innerWidth >= 768) {
        this.$refs.search_input.focus();
      }
    },
    handleOutsideClick(event) {
      const clickedElement = event.target;
      if (!this.$el.contains(clickedElement)) {
        this.visible = false;
      }
    },
    selectIcon(field) {
      this.visible = false;
      this.filter = {};
      if (field) {
        this.filter["field"] = field;
      } else {
        this.filter["field"] = "all";
      }
      this.filter["value"] = this.search_txt;
      this.filter["operator"] = this.operator || "ilike";
      const alert_id = this.$route.query.alert_id
        ? { alert_id: this.$route.query.alert_id }
        : {};
      const query = Object.assign({}, this.$route.query);
      if (this.search_txt) {
        query.filter = this.search_txt;
      } else {
        delete query.filter;
      }

      this.$router
        .replace({ query: { ...query, ...alert_id } })
        .catch(() => {});
      this.$emit("input", this.filter);
    },
    nameOfCustomEventToCall() {
      this.visible = false;
    },
    clickTxt() {
      const page = this.$route.query.page
        ? { page: this.$route.query.page }
        : {};

      if (this.search_txt) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    clearTxt() {
      this.search_txt = "";
      const page = this.$route.query.page
        ? { page: this.$route.query.page }
        : {};
      const alert_id = this.$route.query.alert_id
        ? { alert_id: this.$route.query.alert_id }
        : {};
      const query = Object.assign({}, this.$route.query);

      delete query.filter;

      this.$router.replace({ query }).catch(() => {});

      this.$emit("input", {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.search-filter {
  float: left;
  /*margin-right: 15px;*/
  margin-left: 0px;
  .form-group {
    margin-bottom: 0px !important;
  }
}
.search-card {
  background-color: $white;
  z-index: 9;
  margin-top: 14px;
  max-width: 250px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  .card-body {
    padding: 0rem;
    .searchable-item {
      float: left;
      width: 100%;
      padding: 0.7rem 0.8rem;
      border-bottom: 1px solid #ddd;
      &:hover {
        background-color: rgba(115, 103, 240, 0.12);
        color: var(--primary);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.search-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  margin-top: -17px !important;
  min-width: $percent_100;
  min-height: 32vh;
  padding: 5px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.search-clear {
  padding: 6px 5px 0px 5px;
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  svg {
    margin-top: 3px;
  }
}
input:focus + .input-prefix-img {
  border: 1px solid var(--primary);
}
</style>
